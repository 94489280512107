
import { defineComponent } from 'vue';

import { Actions, Getters, Mutations } from '@/store/enums/ContactSupportEnums';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'view-support-ticket-page',
  computed: {
    ...mapGetters({
      supportTicket: Getters.GET_SUPPORT_TICKET,
    }),
    ticketNo() {
      return this.$route.params.ticketNo;
    },
  },
  methods: {
    ...mapActions({
      retrieveTicket: Actions.FIND_SUPPORT_TICKET,
    }),
  },
});
